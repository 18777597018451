import React, { useState } from 'react';
import { Button, Row, Col, Card, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function RandomGenerator(props) {

  const [inputString, setInputString] = useState("");
  const [selected, setSelected] = useState([]);
  const [settings, setSettings] = useState({
    type: "list",
    multipleDraw: true,
    resultSize: 1,
    numFrom: 0,
    numTo: 10,
    history: false,
  })

  function changeSettings(event, v, defaultInt) {
    let value = null;
    if (v != null) value = v;
    else value = event.target.value;

    if (defaultInt != null) {
      let input = parseInt(value, 10);
      if (!isNaN(input))
        setSettings({ ...settings, [event.target.name]: input });
      else {
        setSettings({ ...settings, [event.target.name]: defaultInt });
        props.showError("Bitte eine Zahl eingeben!");
      }
    }
    else
      setSettings({ ...settings, [event.target.name]: value });
  }

  function calcRandom() {
    if (settings.history === false) setSelected([]);
    switch (settings.type) {
      case "number":
        let r1 = Math.floor(Math.random() * (settings.numTo - settings.numFrom + 1) + settings.numFrom);
        setSelected(selected => [...selected, r1]);
        break;
      case "list":                            //Disable History 
        let items = inputString.split(/[\n,]/);                                     //Split by Comma and linebreack
        items.forEach(element => {                                                  //Remove white spaces in at start and beginning of item
          element.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        });
        items = items.filter(function (entry) { return /\S/.test(entry); });        //Remove empty items
        if (items.length > 0) {
          let r2 = [];

          let index = null;
          /* let size = settings.resultSize;
          if (settings.resultSize > items.length) {
            size = items.length
            setSettings({ ...settings, resultSize: size })
          } */
          // for (let i = 1; i <= size; i++) {
          index = Math.floor(Math.random() * items.length);
          r2.push(items[index]);
          if (settings.multipleDraw === false) {
            items.splice(index, 1);
            setInputString(items.toString());
          }
          //}
          setSelected(selected => [...selected, r2]);
        }
        break;
      default:
        break;
    }
  }

  function changedTextarea(event) {
    setInputString(event.target.value)
  }

  function clearSelected(event) {
    setSelected([]);
  }


  return (
    <Container className="mb-5">
      <Row className="my-3">
        <Col>
          <h1>Zufallsgenerator</h1>
        </Col>
        <Col className="text-right mt-2">
          <Link to="/" className="btn btn-light" name="">Home</Link>
        </Col>
      </Row>
      <Row>
        <Col lg="8">
          <Card>
            <Card.Body>
              <Row>
                <Col md="4" lg="3">
                  <h3 className="text-info">Art</h3>
                  <div className="form-check form-radio-info">
                    <input type="radio" id="tList" name="type" value="list" className="form-check-input" onChange={(e) => changeSettings(e)} checked={settings.type === "list"} />
                    <label className="form-check-label" htmlFor="tList">Eigene Liste</label>
                  </div>
                  <div className="form-check form-radio-info">
                    <input type="radio" id="tNumber" name="type" value="number" className="form-check-input" onChange={(e) => changeSettings(e)} checked={settings.type === "number"} />
                    <label className="form-check-label" htmlFor="tNumber">Zahl</label>
                  </div>
                </Col>
                <Col md="8" lg="9" className="mt-3 mt-md-0">
                  <h3 className="text-info">Einstellungen</h3>
                  <Form className="form">
                    {{
                      "list":
                        <>
                          <div className="form-row">
                            <Col>
                              <textarea className="form-control" placeholder="Werte durch Komma oder Absatz trennen" id="textarea" style={{ "minHeight": "150px" }}
                                value={inputString} onChange={(e) => changedTextarea(e)}></textarea>
                            </Col>
                          </div>
                          <div className="form-row mt-2">
                            {/* <Col>
                              <label htmlFor="resultSize" className="form-label">Anzahl der Ergebnisse</label>
                              <input className="form-control" id="resultSize" type="tel" name="resultSize" value={settings.resultSize} onChange={(e) => changeSettings(e, null, 1)} />
                            </Col> */}
                            <Col>
                              <label htmlFor="history2" className="form-label">Verlauf</label><br />
                              <input type="checkbox" id="history2" data-switch="info" name="history" onChange={(e) => changeSettings(e, !settings.history)} checked={settings.history === true} />
                              <label htmlFor="history2" data-on-label="Ein" data-off-label="Aus"></label>
                            </Col>
                            <Col>
                              <label htmlFor="multipleDraw" className="form-label">Mehrfach ziehen</label><br />
                              <input type="checkbox" id="multipleDraw" data-switch="info" name="multipleDraw" onChange={(e) => changeSettings(e, !settings.multipleDraw)} checked={settings.multipleDraw === true} />
                              <label htmlFor="multipleDraw" data-on-label="Ein" data-off-label="Aus"></label>
                            </Col>
                          </div>
                        </>,
                      "number":
                        <>
                          <div className="form-row">
                            <Col xs="12">
                              <p>Zufällige Zahl innerhalb eines Bereichs ermitteln.</p>
                            </Col>
                            <Col md="6">
                              <label htmlFor="numFrom" className="form-label">Von</label>
                              <input className="form-control" id="numFrom" type="tel" name="numFrom" value={settings.numFrom} onChange={(e) => changeSettings(e, null, 0)} />
                            </Col>
                            <Col md="6">
                              <label htmlFor="numTo" className="form-label">Bis</label>
                              <input className="form-control" id="numTo" type="tel" name="numTo" value={settings.numTo} onChange={(e) => changeSettings(e, null, 0)} />
                            </Col>
                          </div>
                          <div className="form-row mt-2">
                            <Col>
                              <label htmlFor="history1" className="form-label">Verlauf</label><br />
                              <input type="checkbox" id="history1" data-switch="info" name="history" onChange={(e) => changeSettings(e, !settings.history)} checked={settings.history === true} />
                              <label htmlFor="history1" data-on-label="Ein" data-off-label="Aus"></label>
                            </Col>
                          </div>
                        </>

                    }[settings.type]}
                  </Form>
                  <Button onClick={(e) => calcRandom(e)} className="btn btn-info mt-2 float-right" name="calcRandom">Generieren</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" lg="4">
          <Card className="bg-success text-white shadow">
            <Card.Body>
              <Row>
                <Col>
                  <h3>Ergebnis</h3>
                  {selected.length === 0 ? (
                    <></>
                  ) : (<>
                    {selected.map((i, index) => {
                      return (<div key={index}>{i}</div>);
                    })}
                    <Button onClick={(e) => clearSelected(e)} className="btn btn-light mt-2 float-right" name="clearSeleted">Löschen</Button>
                  </>)}
                </Col>
              </Row>
              <Row>
                <Col>


                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RandomGenerator;