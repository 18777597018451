import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import MoneyCounter from "./pages/MoneyCounter";
import DartCounter from "./pages/DartCounter";
import RandomGenerator from "./pages/RandomGenerator";
import DowTraining from "./pages/DowTraining";
import TimeDifference from "./pages/TimeDifference";
import NoPage from "./pages/NoPage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColorPicker from './pages/ColorPicker';


function App() {
  //const reload = () => window.location.reload();

  function showError(text) {
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  function showMessage(text) {
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="moneyCounter" element={<MoneyCounter />} />
          <Route path="dartCounter" element={<DartCounter showError={showError} showMessage={showMessage}/>} />
          <Route path="randomGenerator" element={<RandomGenerator />} />
          <Route path="dowTraining" element={<DowTraining />} />
          <Route path="timeDifference" element={<TimeDifference />} />
          <Route path="colorPicker.html" element={<ColorPicker />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;