import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";


function MoneyCounter(props) {

  const [bills, setBills] = useState(() => {
    // getting stored value
    let saved = localStorage.getItem("bills");
    let initialValue = JSON.parse(saved);
    return initialValue || {
      "500": null, "200": null, "100": null, "50": null, "20": null, "10": null, "5": null
    };
  });

  const [coins, setCoins] = useState(() => {
    // getting stored value
    let saved = localStorage.getItem("coins");
    let initialValue = JSON.parse(saved);
    return initialValue || {
      "2": null, "1": null, "50c": null, "20c": null, "10c": null, "5c": null, "2c": null, "1c": null
    };
  });

  const [billSum, setBillSum] = useState(0);
  const [coinSum, setCoinSum] = useState(0.0);

  useEffect(() => {
    let bs = JSON.stringify(bills);
    localStorage.setItem("bills", bs);
    let cs = JSON.stringify(coins);
    localStorage.setItem("coins", cs);
    calculateSum();
  }, [bills, coins]);

  function calculateSum() {
    let bSum = bills[500] * 500 +
      bills[200] * 200 +
      bills[100] * 100 +
      bills[50] * 50 +
      bills[20] * 20 +
      bills[10] * 10 +
      bills[5] * 5;

    let cSum = coins[2] * 2 +
      coins[1] * 1 +
      coins["50c"] * 0.5 +
      coins["20c"] * 0.2 +
      coins["10c"] * 0.1 +
      coins["5c"] * 0.05 +
      coins["2c"] * 0.02 +
      coins["1c"] * 0.01;

    setCoinSum(cSum);
    setBillSum(bSum);
  }

  function addBill(event) {
    if (event.target.name) {
      setBills({ ...bills, [event.target.name]: parseInt(0 + bills[event.target.name], 10) + 1.0 });
    }
  }

  function subBill(event) {
    if (event.target.name) {
      if (bills[event.target.name] > 0) {
        setBills({ ...bills, [event.target.name]: bills[event.target.name] - 1.0 });
      }
    }
  }

  function addCoin(event) {
    if (event.target.name) {
      setCoins({ ...coins, [event.target.name]: parseInt(0 + coins[event.target.name], 10) + 1.0 });
    }
  }

  function subCoin(event) {
    if (event.target.name) {
      if (coins[event.target.name] > 0) {
        setCoins({ ...coins, [event.target.name]: coins[event.target.name] - 1.0 });
      }
    }
  }

  function handleBillChange(event) {
    if (event.target.value >= 0) {
      setBills({ ...bills, [event.target.name]: event.target.value });
    }
  }

  function handleCoinChange(event) {
    if (event.target.value >= 0) {
      setCoins({ ...coins, [event.target.name]: event.target.value });
    }
  }

  function buttonPressed(e) {
    if (e.target.name === "reset") {
      setBills({ "500": null, "200": null, "100": null, "50": null, "20": null, "10": null, "5": null })
      setCoins({ "2": null, "1": null, "50c": null, "20c": null, "10c": null, "5c": null, "2c": null, "1c": null })
      window.location.reload();
    }
  }

  function handleKeyDown(e) {
    if (e.key.toLowerCase() === "enter" || e.keyCode === 40) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if(index<40)
      form.elements[index + 3].focus();
      e.preventDefault();
    }
   else if (e.keyCode === 38) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if(index>0)
      form.elements[index - 3].focus();
      e.preventDefault();
    }
  }

  return (
    <Container className="mb-5">
      <Row className="my-3">
        <Col>
          <h1>Geldzähler</h1>
          <p>Created by Jonathan</p>
        </Col>
        <Col className="text-right mt-2">
          <Button onClick={(e) => buttonPressed(e)} className="btn btn-light mr-2" name="reset">Reset</Button>
          <Link to="/" className="btn btn-light" name="">Home</Link>
        </Col>
      </Row>
      <Row>
        <Col md="6" lg="4">
          <Card className="bg-info text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="8">
                  <h4>Summe der</h4>
                  <h3>Geldscheine</h3>
                </Col>
                <Col className="text-right">
                  <h3>{billSum} €</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card className="bg-primary text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="6">
                  <h4>Summe der</h4>
                  <h3>Münzen</h3>
                </Col>
                <Col className="text-right">
                  <h3>{coinSum.toFixed(2)} €</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" lg="4">
          <Card className="bg-success text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="6">
                  <h3>Gesamt</h3>
                  <h4>&nbsp;</h4>
                </Col>
                <Col className="text-right">
                  <h3>{(billSum + coinSum).toFixed(2)} €</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Form className="form">
                <div className="form-row">
                  <Col md="6">
                    <h4 className="text-info">Geldscheine</h4>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>500 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="500" value={bills[500]}
                          className="form-control text-right" placeholder="500 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="500">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="500">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>200 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="200" value={bills[200]}
                          className="form-control text-right" placeholder="200 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="200">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="200">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>100 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="100" value={bills[100]}
                          className="form-control text-right" placeholder="100 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="100">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="100">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>50 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="50" value={bills[50]}
                          className="form-control text-right" placeholder="50 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="50">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="50">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>20 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="20" value={bills[20]}
                          className="form-control text-right" placeholder="20 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="20">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="20">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>10 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="10" value={bills[10]}
                          className="form-control text-right" placeholder="10 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="10">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="10">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>5 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleBillChange(e)} name="5" value={bills[5]}
                          className="form-control text-right" placeholder="5 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subBill(e)} name="5">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addBill(e)} name="5">+</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <h4 className="text-primary">Münzen</h4>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>2 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="2" value={coins[2]}
                          className="form-control text-right" placeholder="2 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="2">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="2">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>1 €</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="1" value={coins[1]}
                          className="form-control text-right" placeholder="1 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="1">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="1">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>50 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="50c" value={coins["50c"]}
                          className="form-control text-right" placeholder="0,50 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="50c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="50c">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>20 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="20c" value={coins["20c"]}
                          className="form-control text-right" placeholder="0,20 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="20c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="20c">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>10 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="10c" value={coins["10c"]}
                          className="form-control text-right" placeholder="0,10 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="10c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="10c">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>5 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="5c" value={coins["5c"]}
                          className="form-control text-right" placeholder="0,05 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="5c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="5c">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>2 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="2c" value={coins["2c"]}
                          className="form-control text-right" placeholder="0,02 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="2c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="2c">+</Button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3 col-md-2"><h4>1 ct</h4></div>
                      <div className="col-1"></div>
                      <div className="col-5">
                        <input type="tel" onKeyDown={handleKeyDown} onChange={(e) => handleCoinChange(e)} name="1c" value={coins["1c"]}
                          className="form-control text-right" placeholder="0,01 €" />
                      </div>
                      <div className="col-2">
                        <div className="btn-group mb-2">
                          <Button type="button" className="btn btn-light" onClick={(e) => subCoin(e)} name="1c">-</Button>
                          <Button type="button" className="btn btn-light" onClick={(e) => addCoin(e)} name="1c">+</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default MoneyCounter;