import React from 'react'
import PropTypes from 'prop-types'

function colorPicker(props) {
    
    window.location.reload();
  return (
   <></>
  )
}


export default colorPicker
