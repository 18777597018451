import React, { useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function DowTraining(props) {

    const [dateList, setDateList] = useState([]);
    const [fromDate, setFromDate] = useState("1600-01-01");
    const [toDate, setToate] = useState("2099-12-31");

    dateList[0] = {date:"2017-02-26",dd:2,dow:7,cdd:null,cdow:null}

    function generate() {

    }

    function handleInputChange(event) {
        if (event.target.value) {
          setDateList({ ...dateList, [event.target.name]: event.target.value });
        }
      }

    return (
        <Container className="mb-5">
            <Row className="my-3">
                <Col>
                    <h1>DoW - Trainer</h1>
                </Col>
                <Col className="text-right mt-2">
                    <Link to="/" className="btn btn-light" name="">Home</Link>
                </Col>
            </Row>
            <Row>
                <Col sm="12" lg="4">
                    <Card>
                        <Card.Body>
                            <p>Anfangs- und Enddatum zum Generieren auswählen</p>
                            <div className="form-row">
                                <Col>
                                <label htmlFor="dateFrom" className="form-label">Von</label>
                                <input className="form-control" id="dateFrom" type="tel" name="numFrom" value="1600-01-01" />
                                </Col>
                            </div>

                            <div className="form-row mt-2">
                                <Col>
                                <label htmlFor="dateTO" className="form-label">Bis</label>
                                <input className="form-control" id="dateTo" type="tel" name="numTo" value="2099-12-31" />
                                </Col>
                            </div>

                            <div className="form-row mt-2">
                                <Col>
                                <label htmlFor="numTo" className="form-label">Anzahl der Daten</label>
                                <input className="form-control" id="numTo" type="tel" name="numTo" value="2" />
                                </Col>
                            </div>

                            <div className="form-row mt-2">
                                <Col>
                                    <Button onClick={(e) => (e)} className="btn btn-info float-right" name="generate">Generieren</Button>
                                    </Col> </div> 

                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="12" lg="8">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h4>Datum</h4>
                                </Col>
                                <Col className="d-none d-sm-block">
                                    <h4>Hilfsfeld</h4>
                                </Col>
                                <Col>
                                    <h4>DD</h4>
                                </Col>
                                <Col>
                                    <h4>DoW</h4>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <p className='d-flex align-items-center h-100'>26. Feb. 2017</p>
                                </Col>
                                <Col className="d-none d-sm-block">
                                    <input className="form-control" id="numTo" type="tel" name="numTo" value="2151" />
                                </Col>
                                <Col>
                                    <input className="form-control" id="numTo" type="tel" name="numTo" value="2" />
                                </Col>
                                <Col>
                                    <input className="form-control" id="numTo" type="tel" name="numTo" value="7" />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <p className='d-flex align-items-center h-100'>04. Aug. 1935</p>
                                </Col>
                                <Col className="d-none d-sm-block">
                                    <input className="form-control" id="numTo" type="tel" name="numTo" />
                                </Col>
                                <Col>
                                    <input className="form-control" id="numTo" type="tel" name="numTo" />
                                </Col>
                                <Col>
                                    <input className="form-control" id="numTo" type="tel" name="numTo" />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Button onClick={(e) => (e)} className="btn btn-success float-right" name="check">Überprüfen</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default DowTraining
