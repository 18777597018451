import React from 'react';
import { Button, Row, Col, Card, Form, Container } from "react-bootstrap";


function NoPage(props) {
    return (
        <Container className="mb-5">
            <Row className="my-3">
                <Col>
                    <h1>404</h1>
                    <h2>Not found</h2>
                </Col>
            </Row>
        </Container>
    )
}

export default NoPage
