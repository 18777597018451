import React, { useState } from 'react';
import { Button, Row, Col, Card } from "react-bootstrap";

function TimeDifference(props) {

  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [diff, setDiff] = useState(date2 - date1);

  function calcDifference() {
    setDate1(new Date('2021-09-17T12:24:00'));
    setDate2(new Date());
    setDiff(date2 - date1);
  }


  return (
    <>
      <Row className="my-3">
        <Col>
          <h1>Zeitabstände</h1>
          <p>berechnen</p>
        </Col>
        <Col className="text-right mt-2">
          <Button onClick={(e) => props.changeTool(e)} className="btn btn-light" name="">Home</Button>
        </Col>
      </Row>
      <Row>
        <Col md="6" lg="4">
          <Card className="bg-info text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="8">
                  {diff}
                </Col>
                <Col className="text-right">
                  <h3>H1</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card className="bg-primary text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="6">

                </Col>
                <Col className="text-right">
                  <h3>H2</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" lg="4">
          <Card className="bg-success text-white shadow">
            <Card.Body>
              <Row>
                <Col xs="6">

                </Col>
                <Col className="text-right">
                  <h3>H3</h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={(e) => calcDifference(e)} className="btn btn-success" name="">Berechnen</Button>
        </Col>
      </Row>
    </>
  );
}

export default TimeDifference;