import React, { useState } from 'react';
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import ToolIMG from '../img/Maintenance-rafiki.png';
import { Link } from "react-router-dom";


function Dashboard(props) {
  return (<>
    <section className="hero-section pt-3 pb-5">
      <div className="container px-3">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="mt-4 mt-xl-0">
              <p className="font-14 text-white-50">Du hast es gefunden. Hier gibt es</p>
              <h2 className="text-white fw-normal mb-1 mt-3 hero-title">
                Online Werkzeuge &amp; Tools
              </h2>

              <p className="mb-4 font-16 text-white-50">die den Alltag erleichtern sollen. Von einem automatisierten
                Geldzähler bis hin zu ???. I waß noch nit, wos olles kummt, oba do muas daweil a Text stehen, damit es a bissi besser ausschaut &#58;&#41;</p>
              <Link to="/moneyCounter" name="moneyCounter" className="btn btn-info btn-dm-dark">Geldzähler</Link>
            </div>
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="text-md-end mt-3 mt-md-0">
              <img src={ToolIMG} alt="Werkzeug" className="d-none d-md-inline img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Container className="px-3 mb-5">
      <Row className="mt-5">
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Geldzähler</h3>
              <p>Summe berechnen von Geldscheinen und Münzen mithilfe der jeweiligen Anzahl</p>
              <Link to="/moneyCounter" className="btn btn-success" name="moneyCounter">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Dart Counter</h3>
              <p>Weil i online irgendwie nix gscheides (und schenes) gfundn hob &#58;&#41;</p>
              <Link to="/dartCounter" className="btn btn-success" name="dartCounter">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Zufallsgenerator</h3>
              <p>Für faire Entscheidungen zum Beispiel oder zur Teambildung bei Spielen</p>
              <Link to="/randomGenerator" className="btn btn-success" name="randomGenerator">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Color Picker</h3>
              <p>Nicht von mir programmiert, aber es ist trotzdem gut, das online zu haben</p>
              <Link to="/colorPicker.html" className="btn btn-success" name="colorPicker">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Day of Week</h3>
              <p>Nur zum Üben. Wochentag von einem zufälligen Datum herausfinden</p>
              <Link to="/dowTraining" className="btn btn-success" name="">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card>
            <Card.Body>
              <h3>Zeitabstände</h3>
              <p>Zeitlichen Abstand zwischen zwei Tagen &amp; Uhrzeit berechnen</p>
              <Link to="/" className="btn btn-light" name="">Coming someday</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>);
}

export default Dashboard;